import SlideOver from "./SlideOverComponent";
import PropTypes from 'prop-types';
import { useContext } from "react";
import { UsersContext, AuthContext } from "../App";
import CloutIcon from "../Icons/CloutIcon";

export default function WalletSlideOver(props) {
    const usersData = useContext(UsersContext);
    const identityData = useContext(AuthContext);

    const content = (
        <>
            <div className="text-center text-gray-500">Total Balance</div>
            <div className="text-center text-lg font-semibold"><CloutIcon className="h-5 w-5" />{usersData?.[identityData.publicKeyAdded]?.balanceClout}</div>
            <div className="text-center text-lg font-semibold">$ {usersData?.[identityData.publicKeyAdded]?.balanceInUsd}</div>
        </>
    );

    return (
        <SlideOver title="My wallet" content={content} open={props.open} setOpen={props.setOpen} />
    );
}

WalletSlideOver.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}